import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { ElMessage } from "element-plus"
import { get } from "lodash-es"
import { getToken } from "@/utils/cookies"
import { usePermissionStore } from "@/store/modules/permission"

/** 创建请求实例 */
function createService() {
  // 创建一个 axios 实例
  const service = axios.create()
  // 请求拦截
  service.interceptors.request.use(
    async (config) => {
      // console.log(config)
      config.timeout = config.timeout ?? 5000
      let permission = usePermissionStore()
      let token = getToken() == undefined ? "" : getToken()
      // 除登录页 其他页面调用签名
      if (config.url.indexOf("/users/login") == -1) {
        if (config.url.indexOf("/users/user_info") != -1 || permission.timestamp == 0 || new Date().getTime() - permission.timestamp * 1000 > 90 * 1000) {
          // 调用签名
          await getPermission()
        }
      }
      // vue2；项目中文件上传接口类型改为data类型
      if (config.url.indexOf("/api/workflows/file") != -1) {
        config.headers["Content-Type"] = "multipart/form-data;boundary=" + new Date().getTime()
      }
      // headers 追加token
      if (token) {
        config.headers["Token"] = token
      }
      // headers 追加签名
      if (permission.signature) {
        config.headers["signature"] = permission.signature
      }
      // 允许跨域
      config.withCredentials = true
      return config
    },
    // 发送失败
    (error) => Promise.reject(error)
  )
  // 响应拦截（可根据具体业务作出相应的调整）
  service.interceptors.response.use(
    (response) => {
      // apiData 是 api 返回的数据
      const apiData = response.data as any
      // 这个 code 是和后端约定的业务 code
      const code = apiData.code
      // 如果没有 code, 代表这不是项目后端开发的 api
      if (code === undefined) {
        ElMessage.error("非本系统的接口")
        return Promise.reject(new Error("非本系统的接口"))
      } else {
        switch (code) {
          case 0:
            // code === 0 代表没有错误
            return apiData
          case -1:
            // code === -1 代表错误
            return apiData
          case 20000:
            // code === 20000 代表没有错误
            return apiData
          default:
            // 不是正确的 code
            ElMessage.error(apiData.msg || "Error")
            return Promise.reject(new Error("Error"))
        }
      }
    },
    (error) => {
      // status 是 HTTP 状态码
      const status = get(error, "response.status")
      console.log("error------------------------------", error)

      switch (status) {
        case 400:
          error.message = "请求错误"
          break
        case 401:
          error.message = "未授权，请登录"
          break
        case 403:
          // token 过期时，直接退出登录并强制刷新页面（会重定向到登录页）
          // useUserStoreHook().logout()
          location.reload()
          break
        case 404:
          error.message = "请求地址出错"
          break
        case 408:
          error.message = "请求超时"
          break
        case 500:
          error.message = "服务器内部错误"
          break
        case 501:
          error.message = "服务未实现"
          break
        case 502:
          error.message = "网关错误"
          break
        case 503:
          error.message = "服务不可用"
          break
        case 504:
          error.message = "网关超时"
          break
        case 505:
          error.message = "HTTP版本不受支持"
          break
        default:
          break
      }
      // 执行终端命令 取消
      if (error.config.url != "api/terminal" && error.config.method != "post" && status != 504) {
        ElMessage.error(error.message)
      }
      return Promise.reject(error)
    }
  )
  return service
}

/** 创建请求方法 */
function createRequestFunction(service: AxiosInstance) {
  return async function (config: AxiosRequestConfig) {
    let _config = { BaseAPI: import.meta.env.VITE_BASE_API }
    await fetch("/config.json")
      .then((response) => response.json())
      .then((data: any) => {
        _config = data
      })
      .catch((e) => {
        console.log("config-Error", e)
      })
    const configDefault = {
      headers: {
        secret: import.meta.env.VITE_HEADER_SECRET,
        signature: "",
        Token: getToken(),
        // 携带 token
        // "X-Access-Token": getToken(),
        "Content-Type": get(config, "headers.Content-Type", "application/json")
      },
      timeout: config.timeout,
      // baseURL: import.meta.env.VITE_BASE_API,
      baseURL: _config.BaseAPI,
      data: {}
    }
    return service(Object.assign(configDefault, config))
  }
}

// 获取签名
async function getPermission() {
  let _config = { BaseAPI: import.meta.env.VITE_BASE_API }
  await fetch("/config.json")
    .then((response) => response.json())
    .then((data) => {
      _config = data
    })
    .catch((e) => {
      console.log("config-Error", e)
    })
  //
  let token_str = getToken() == undefined ? "" : getToken()
  // console.log("getPermission_token", token_str)
  // console.log("getPermission_baseurl", process.env.VUE_APP_BASE_URL)
  //
  await axios({
    // url: import.meta.env.VITE_BASE_API + "/api/permission",
    url: _config.BaseAPI + "/api/permission",
    headers: {
      Token: token_str,
      secret: "8cd585da-3cv6-11e8-9946-784f437vaad6-secret"
    },
    method: "get"
  })
    .then((req) => {
      // console.log("req", req)
      let { data } = req
      if (data.code == 0) {
        let permission = usePermissionStore()
        permission.timestamp = data.data.timestamp
        permission.signature = data.data.signature
      }
    })
    .catch((e) => {
      console.log("error", e)
    })
}

/** 用于网络请求的实例 */
export const service = createService()

/** 用于网络请求的方法 */
export const request = createRequestFunction(service)
