import { request } from "@/utils/service"

interface IUserRequestData {
  username: string
  password: string
}

/** 登录，返回 token */
export const accountLogin = (data: IUserRequestData) => {
  return request({
    url: "api/system/users/login",
    method: "post",
    data
  })
}
/** 获取用户详情 */
export const userInfoRequest = () => {
  return request({
    url: "api/system/users/user_info",
    method: "get"
  })
}

// 退出登录
export function logout() {
  return request({
    url: "/api/system/users/login_out",
    method: "post"
  })
}
