import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router"
const Layout = () => import("@/layout/index.vue")

/** 常驻路由 */
export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: "/redirect",
    component: Layout,
    meta: {
      hidden: true
    },
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index.vue")
      }
    ]
  },
  {
    path: "/login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      hidden: true
    }
  },
  {
    path: "/system",
    component: () => import("@/views/system/index.vue"),
    meta: {
      hidden: true
    }
  },
  //
  {
    path: "/system",
    component: Layout,
    redirect: "noRedirect",
    name: "系统后台",
    meta: {
      title: "系统后台",
      icon: "component"
    },
    children: [
      {
        path: "/system/index",
        component: () => import("@/views/system/index.vue"),
        name: "index",
        meta: { title: "首页" }
      }
    ]
  },

  // {
  //   path: "/",
  //   redirect: "/text"
  //   children: [
  //     {
  //       path: "codes",
  //       component: () => import("@/views/web_ide/index.vue")
  //     }
  //   ]
  // },
  {
    path: "/",
    redirect: "/index",
    meta: {
      hidden: true
    }
  },
  {
    path: "/index",
    component: () => import("@/views/web_ide/index.vue"),
    meta: {
      hidden: true
    }
  },
  {
    path: "/ai/:questionnaire_id",
    component: () => import("@/views/web_ide/ai.vue"),
    meta: {
      hidden: true
    }
  }
]

/**
 * 动态路由
 * 用来放置有权限（roles 属性）的路由
 * 必须带有 name 属性
 */
export const asyncRoutes: Array<RouteRecordRaw> = []

const router = createRouter({
  // history: createWebHashHistory("/"),
  // routes: constantRoutes
  history: createWebHistory(),
  routes: constantRoutes as unknown as RouteRecordRaw[]
})

/** 重置路由 */
export function resetRouter() {
  // 注意：所有动态路由路由必须带有 name 属性，否则可能会不能完全重置干净
  try {
    router.getRoutes().forEach((route) => {
      const { name, meta } = route
      if (name && meta.roles?.length) {
        router.hasRoute(name) && router.removeRoute(name)
      }
    })
  } catch (error) {
    // 强制刷新浏览器，不过体验不是很好
    window.location.reload()
  }
}

export default router
