/** 注册的主题 */
const themeList = [
  {
    title: "默认",
    name: "normal"
  },
  {
    title: "黑暗",
    name: "dark"
  }
]

export default themeList
