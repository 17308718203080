import CryptoJS from "crypto-js/crypto-js"
// 默认的 KEY 与 iv ，可以和后端商议好，只要统一的给16位字符串即可
const KEY = CryptoJS.enc.Utf8.parse("0904wovon1003102")
const IV = CryptoJS.enc.Utf8.parse("1234567890123456")

/**
 * v.
 * AES加密
 */
export function AES_Encrypt(word: string, keyStr = undefined, ivStr = undefined) {
  let key = KEY
  let iv = IV
  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr)
    iv = CryptoJS.enc.Utf8.parse(ivStr)
  }
  // 对字节型数据进行长度判断
  // while (word.length % 16 != 0){
  //   word += " " // 如果字节型数据长度不是16倍整数就进行补充
  // }
  let srcs = CryptoJS.enc.Utf8.parse(word)
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7 // Pkcs7  ZeroPadding
  })
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}

/**
 * v.
 * AES 解密
 */
export function AES_Decrypt(word: string, keyStr = undefined, ivStr = undefined) {
  let key = KEY
  let iv = IV
  if (keyStr) {
    key = CryptoJS.enc.Utf8.parse(keyStr)
    iv = CryptoJS.enc.Utf8.parse(ivStr)
  }
  let base64 = CryptoJS.enc.Base64.parse(word)
  let src = CryptoJS.enc.Base64.stringify(base64)
  var decrypt = CryptoJS.AES.decrypt(src, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7 // Pkcs7  ZeroPadding
  })
  var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}
