/* eslint-disable */
import { createApp, Directive } from "vue"
import router from "./router"
import "@/router/permission"
import { setupStore } from "./store"
import App from "./App.vue"
import ElementPlus from "element-plus"
import loadSvg from "@/icons"
import * as directives from "@/directives"
import * as ElementPlusIconsVue from "@element-plus/icons-vue"
import "uno.css"
import "normalize.css"
import "element-plus/dist/index.css"
import "element-plus/theme-chalk/dark/css-vars.css"
import "@/styles/index.scss"
// import svgIcon from "@/components/SvgIcon/index.vue"
import VirTree from "vue-virtual-tree"
import { ElMessage, ElNotification } from "element-plus"
// import "default-passive-events"
//全局加密解密
import { AES_Encrypt, AES_Decrypt } from "@/utils/aes.js"

const app = createApp(App)
//
app.config.globalProperties.$AES_Encrypt = AES_Encrypt //全局加密
app.config.globalProperties.$AES_Decrypt = AES_Decrypt //全局解密
app.config.globalProperties.$notify = ElNotification //Notification 通知
/** 外部config */
let vConfig = { URL: import.meta.env.VITE_BASE_API }
fetch("/config.json")
  .then((response) => response.json())
  .then((data: any) => {
    vConfig = data
    // console.log("_config.value", vConfig)
  })
  .catch((e) => {
    console.log("config-Error", e)
  })
  .finally(() => {
    app.config.globalProperties.v = vConfig

    //
    /** element-plus 组件完整引入 */
    // app.use(ElementPlus)
    // Configure store
    // 配置 store
    setupStore(app)
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
    }
    // app.use(ElementPlus).use(router).use(VirTree).component("svg-icon", svgIcon).mount("#app")
    app.use(ElementPlus).use(router).use(VirTree).mount("#app")
    /** 加载全局 svg */
    loadSvg(app)
    /** 自定义指令 */
    Object.keys(directives).forEach((key) => {
      app.directive(key, (directives as { [key: string]: Directive })[key])
    })
  })
