/* @vite-ignore */
import { store } from "@/store"
import { defineStore } from "pinia"
// import { RouteRecordRaw } from "vue-router"
import { constantRoutes, asyncRoutes } from "@/router"
import { getToken, removeToken, setToken } from "@/utils/cookies"
import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router"
const Layout = () => import("@/layout/index.vue")
import { useUserStoreHook } from "@/store/modules/user"
import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

interface IPermissionState {
  routes: RouteRecordRaw[]
  dynamicRoutes: RouteRecordRaw[]
  signature: ""
  timestamp: 0
}
// export const loadView = (view) => {
//   // console.log(view)
//   return (resolve) => require(`@/views/${view}`)
// }
// 获取组件
export const loadView: any = (component_str: string) => {
  component_str = component_str.indexOf(".vue") != -1 ? component_str : component_str + ".vue"
  let modules = import.meta.glob("/src/views/*/*.vue")
  let _module = modules[`/src/views/${component_str}`]
  if (_module) {
    return _module
  }
  modules = import.meta.glob("/src/views/*/*/*.vue")
  _module = modules[`/src/views/${component_str}`]
  if (_module) {
    return _module
  }
  modules = import.meta.glob("/src/views/*/*/*/*.vue")
  _module = modules[`/src/views/${component_str}`]
  if (_module) {
    return _module
  }
  return undefined
}

// 递归用户路由
export function routerRecursive(user_router, parent_id) {
  const userRouter: RouteRecordRaw[] = []
  let parentRouter = []
  user_router.forEach((router) => {
    if (router.parent_id == parent_id) {
      parentRouter.push(router)
    }
  })
  if (parentRouter.length > 0) {
    parentRouter.forEach((parent) => {
      let result = routerRecursive(user_router, parent.id)
      // 有子路由
      if (result.length > 0) {
        let _this_data: RouteRecordRaw = {} as RouteRecordRaw
        if (parent.component == "Layout") {
          _this_data.component = Layout
        } else {
          _this_data.component = loadView(parent.component)
        }
        _this_data.name = parent.name
        _this_data.path = parent.path
        _this_data.redirect = parent.redirect
        _this_data.meta = {
          title: parent.name,
          icon: "menu",
          params: parent.params,
          link_address: parent.link_address,
          router: parent.path,
          hidden: parent.is_hidden,
          singleton: true
        }
        _this_data.children = result
        userRouter.push(_this_data)
      }
      // 无子路由
      else {
        let _this_data: RouteRecordRaw = {} as RouteRecordRaw
        _this_data = {
          name: parent.name,
          component: loadView(parent.component),
          path: parent.path,
          meta: {
            title: parent.name,
            icon: parent.icon,
            params: parent.params,
            link_address: parent.link_address,
            router: parent.path,
            hidden: parent.is_hidden,
            singleton: true
          }
        }
        // child_data.router_params = parent.params
        // child_data.link_address = parent.link_address
        //console.log('child_data',child_data)
        userRouter.push(_this_data)
      }
    })
  }
  return userRouter
}

const hasPermission = (roles: string[], route: RouteRecordRaw) => {
  if (route.meta && route.meta.roles) {
    // return roles.some((role) => {
    //   if (route.meta?.roles !== undefined) {
    //     return route.meta.roles.includes(role)
    //   } else {
    //     return false
    //   }
    // })
    return true
  } else {
    return true
  }
}

const filterAsyncRoutes = (routes: RouteRecordRaw[], roles: string[]) => {
  const res: RouteRecordRaw[] = []
  routes.forEach((route) => {
    const r = { ...route }
    if (hasPermission(roles, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, roles)
      }
      res.push(r)
    }
  })
  return res
}

export const usePermissionStore = defineStore({
  id: "permission",
  state: (): IPermissionState => {
    return {
      routes: [],
      dynamicRoutes: [],
      signature: "",
      timestamp: 0
    }
  },
  actions: {
    setRoutes(roles: string[], user_router: any) {
      let accessedRoutes
      if (user_router) {
        accessedRoutes = routerRecursive(user_router, "")
        accessedRoutes.push({
          path: "/:pathMatch(.*)*", // 必须将 'ErrorPage' 路由放在最后
          // component: Layout,
          redirect: "/404",
          name: "ErrorPage",
          meta: {
            title: "错误页面",
            icon: "404",
            hidden: true
          },
          children: [
            {
              path: "401",
              component: () => import("@/views/error-page/401.vue"),
              name: "401",
              meta: {
                title: "401"
              }
            },
            {
              path: "404",
              component: () => import("@/views/error-page/404.vue"),
              name: "404",
              meta: {
                title: "404"
              }
            }
          ]
        })
      }
      this.routes = constantRoutes.concat(accessedRoutes)
      this.dynamicRoutes = accessedRoutes
    },
    // 设置签名
    setSignature(signature: string) {
      this.signature = signature
    },
    // 设置时间戳
    setTimestamp(timestamp: string) {
      this.timestamp = timestamp
    },
    // 通过用户token获取 timestamp，signature
    async getPermission() {
      let token_str = getToken() == undefined ? "" : getToken()
      //
      await axios({
        url: import.meta.env.VITE_BASE_API + "/api/permission",
        headers: {
          Token: token_str,
          secret: import.meta.env.VITE_HEADER_SECRET,
        },
        method: "get"
      })
        .then((req) => {
          let { data } = req
          if (data.code == 0) {
            this.timestamp = data.data.timestamp
            this.signature = data.data.signature
          }
        })
        .catch((e) => {
          console.log("error", e)
        })
      return {token: token_str, timestamp: this.timestamp, signature: this.signature}
    }
  }
})

/** 在 setup 外使用 */
export function usePermissionStoreHook() {
  return usePermissionStore(store)
}
