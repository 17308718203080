import { store } from "@/store"
import { defineStore } from "pinia"
import { usePermissionStore } from "./permission"
import { getToken, removeToken, setToken } from "@/utils/cookies"
import router, { resetRouter } from "@/router"
import { accountLogin, logout, userInfoRequest } from "@/api/login"
import { RouteRecordRaw } from "vue-router"

interface IUserState {
  token: string
  roles: []
  name: string
  id: number
  account: string
  user_router: []
  departments: []
  user_number: string
  user_town_identity: string
  user_router_identity: string
  towns: []
}

export const useUserStore = defineStore({
  id: "user",
  state: (): IUserState => {
    return {
      token: getToken() || "",
      roles: [],
      name: "",
      id: 0,
      account: "",
      user_router: [],
      departments: [],
      user_number: "",
      user_town_identity: "",
      user_router_identity: "",
      towns: []
    }
  },
  actions: {
    // /** 设置角色数组 */
    // setRoles(roles: string[]) {
    //   this.roles = roles
    // },
    /** 登录 */
    login(userInfo: { username: string; password: string }) {
      return new Promise((resolve, reject) => {
        accountLogin({
          username: userInfo.username.trim(),
          password: userInfo.password
        })
          .then((res: any) => {
            setToken(res.data.token)
            this.token = res.data.token
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /** 获取用户详情 */
    getInfo() {
      return new Promise((resolve, reject) => {
        userInfoRequest()
          .then((res: any) => {
            // console.log("userInfoRequest_res", res)
            const { roles, name, id, account, user_router, departments, user_number, user_town_identity, user_router_identity, towns } = res.data
            this.roles = roles
            this.name = name
            this.id = id
            this.account = account
            this.user_router = user_router
            this.departments = departments
            this.user_number = user_number
            this.user_town_identity = user_town_identity
            this.user_router_identity = user_router_identity
            this.towns = towns
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // /** 切换角色 */
    // async changeRoles(role: string) {
    //   const token = role + "-token"
    //   this.token = token
    //   setToken(token)
    //   await this.getInfo()
    //   const permissionStore = usePermissionStore()
    //   permissionStore.setRoutes(this.roles, this.user_router)
    //   resetRouter()
    //   permissionStore.dynamicRoutes.forEach((item: RouteRecordRaw) => {
    //     router.addRoute(item)
    //   })
    // },
    /** 登出 */
    logout() {
      return new Promise((resolve, reject) => {
        logout()
          .then((res) => {
            removeToken()
            this.token = ""
            this.roles = []
            this.roles = []
            this.name = ""
            this.id = 0
            this.account = ""
            this.user_router = []
            this.departments = []
            this.user_number = ""
            this.user_town_identity = ""
            this.user_router_identity = ""
            this.towns = []
            resetRouter()
            resolve(res)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    /** 重置 token */
    resetToken() {
      removeToken()
      this.token = ""
      this.roles = []
    }
  }
})

/** 在 setup 外使用 */
export function useUserStoreHook() {
  return useUserStore(store)
}
